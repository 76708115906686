<template>
	<div class="animated fadeIn">
		<user-event-ticket-form
			:form-import="form"
			:loading="loading"
			:ticket-information="data"
			:errors-import="errors.errors"
			:transfer-errors-import="transferErrors.errors"
			:edits-message="editsMessage"
			@requestData="requestEditEventTicket"
			@transferTicketData="requestTransferEventTicket" />
	</div>
</template>
<script>

import Ticket from '@/util/EventTickets';
import { EventTickets } from '@/translations';
import UserEventTicketForm from './components/UserEventTicketForm';

export default {
	name: 'UserEditEventTicket',
	messages: [EventTickets],
	components: {
		UserEventTicketForm,
	},
	data() {
		return {
			ticket: new Ticket(),
			editTicket: new Ticket(),
			transferTicket: new Ticket(),
			form: {
				first_name: '',
				last_name: '',
				guest_number: 0,
			},
			alert: new this.$Alert(),
		};
	},
	computed: {
		response() {
			try {
				const { data } = this.ticket.data.response;
				return data;
			} catch (error) {
				return {};
			}
		},
		data: {
			get() {
				try {
					return this.response.data.attributes;
				} catch (error) {
					return {};
				}
			},
			set(payload) {
				this.response.data.attributes = { ...this.response.data.attributes, ...payload };
			},
		},
		errors() {
			return this.editTicket.data.errors;
		},
		transferErrors() {
			return this.transferTicket.data.errors;
		},
		loading() {
			try {
				return this.ticket.data.loading;
			} catch (error) {
				return false;
			}
		},
		routeId() {
			return this.$route.params.ticketId;
		},
		ticketNumber() {
			try {
				return this.response.data.attributes.ticket_number;
			} catch (error) {
				return '';
			}
		},
		editsMessage() {
			try	{
				if (this.response.data.attributes.remaining_edits > 1) {
					const remainingEdits = this.response.data.attributes.remaining_edits;
					return this.translate('edits_alert_plural', { times: remainingEdits });
				}

				return this.translate('edits_alert');
			} catch (error) {
				return '';
			}
		},
		confirmationTitle() {
			try	{
				if (this.response.data.attributes.remaining_edits > 1) {
					const remainingEdits = this.response.data.attributes.remaining_edits;
					return this.translate('confirmation_title_plural', { times: remainingEdits });
				}

				return this.translate('confirmation_title');
			} catch (error) {
				return '';
			}
		},
	},
	watch: {
		routeId: () => {
			this.ticket.getTicket(this.routeId);
		},
		ticketNumber() {
			try {
				const ticket = this.response.data.attributes.ticket_number;
				this.$parent.titleParam = { ticket };
			} catch (error) {
				this.$parent.titleParam = { ticket: '' };
			}
		},
	},
	created() {
		this.ticket.getTicket(this.routeId).then(() => {
			if (!this.response.data.attributes.can_update_ticket || (this.response.data.attributes.remaining_edits <= 0 && this.response.data.attributes.remaining_transfers <= 0)) {
				this.$router.push({ name: 'EventTicketsAll' });
			}

			this.setFocus('first_name');
		});

		this.$parent.titleParam = { ticket: '' };
	},
	methods: {
		requestEditEventTicket(payload) {
			const options = {
				confirmButtonText: this.translate('yes'),
				cancelButtonText: this.translate('cancel'),
			};

			this.alert.confirmation(this.confirmationTitle, this.translate('confirmation_text'), options, false).then((result) => {
				if (result.value) {
					this.editTicket.updateTicketNames(this.routeId, payload).then(() => {
						this.alert.toast('success', this.translate('ticket_updated'));
						this.$router.push({ name: 'EventTicketsAll' });
					}).catch(() => { this.data = payload; });
				}
			}).catch(() => {});
		},
		requestTransferEventTicket(payload) {
			const options = {
				confirmButtonText: this.translate('yes'),
				cancelButtonText: this.translate('cancel'),
			};

			this.alert.confirmation(this.translate('transfer_confirmation_title'), this.translate('transfer_confirmation_text'), options, false).then((result) => {
				if (result.value) {
					this.transferTicket.transferTicket(this.routeId, payload).then(() => {
						this.alert.toast('success', this.translate('ticket_transferred'));
						this.$router.push({ name: 'EventTicketsAll' });
					}).catch(() => { this.data = payload; });
				}
			}).catch(() => {});
		},
	},
};
</script>
