<template>
	<div class="row">
		<div class="col-12">
			<is-loading
				:loading="loading"
				:has-data="hasData"
				:loading-label="translate('loading')"
				:no-data-label="translate('ticket_not_found')" />
		</div>
		<div
			v-if="showForm"
			class="col-12">
			<b-card
				no-body
				class="h-100">
				<template v-slot:header>
					<h4 class="mb-0">
						{{ translate('edit_ticket_information') }}
					</h4>
				</template>
				<b-card-body class="pb-3">
					<div>
						<b-alert
							class="mt-2"
							:show="!canEdit"
							variant="danger">
							<p
								class="my-0 mb-1"
								style="color: #1D1B1B">
								<strong>{{ translate('cant_edit_message') }}</strong>
							</p>
						</b-alert>
						<b-form
							v-if="canEdit"
							class="row"
							@submit.prevent=""
							@keydown="clearError($event.target.name);"
							@change="clearError($event.target.name);">
							<div class="col-12">
								<b-alert
									class="mt-2"
									show
									variant="warning">
									<p
										class="my-0 mb-1"
										style="color: #1D1B1B">
										<strong>{{ translate('important') }}</strong>
										<br>
										{{ editsMessage }}
									</p>
								</b-alert>
							</div>

							<div class="form-group col-12">
								<label
									for="event_code_name"
									class="label">{{ translate('event') }}
									<span class="text-danger">*</span></label>
								<select
									id="event_code_name"
									v-model="form.event_code_name"
									name="event_code_name"
									:class="hasError('event_code_name') ? 'is-invalid' : ''"
									class="form-control"
									disabled>
									<option
										v-for="event in events"
										:key="event"
										selected
										:value="event">
										{{ translate(event) }}
									</option>
								</select>
								<template v-if="hasError('event_code_name')">
									<span
										v-for="error in errors['event_code_name']"
										:key="error"
										class="invalid-feedback animated fadeIn"
										v-text="error" />
								</template>
							</div>

							<div class="form-group col-md-6 col-sm-12">
								<input-text
									id="first_name"
									:label="translate('first_name')"
									:setter-value="form.first_name"
									:errors="errors['first_name']"
									:required="true"
									type="text"
									@dataChanged="form.first_name = $event" />
							</div>

							<div class="form-group col-md-6 col-sm-12">
								<input-text
									id="last_name"
									:label="translate('last_name')"
									:setter-value="form.last_name"
									:errors="errors['last_name']"
									:required="true"
									type="text"
									@dataChanged="form.last_name = $event" />
							</div>
							<!-- <div class="form-group col-md-2 col-sm-12">
								<label class="label"> {{ translate('guest_number') }} </label>
								<number-input-spinner
									v-model="form.guest_number"
									:mouse-down-speed="500"
									:min="0"
									:max="99"
									:button-class="'vnis__button custom-height bg-primary-alt'"
									:input-class="'vnis__input custom-height custom-input-width'"
									:integer-only="true"
									class="col" />
							</div> -->

							<div class="col-12">
								<button
									:disabled="Object.keys(errors).length > 0"
									class="btn btn-primary float-right"
									@click="ticketInfoSubmit">
									{{ translate('save') }}
								</button>
							</div>
						</b-form>
					</div>
				</b-card-body>
			</b-card>
		</div>
		<!-- ToDo: remove false added for Velocity Tour Event -->
		<div
			v-if="showForm && ticketInformation.is_enabled && false"
			class="col-12 mt-3">
			<b-card
				no-body
				class="h-100">
				<template v-slot:header>
					<h4 class="mb-0">
						{{ translate('transfer_ticket') }}
					</h4>
				</template>
				<b-card-body class="pb-3">
					<div>
						<b-alert
							class="mt-2"
							:show="!canTransfer"
							variant="danger">
							<p
								class="my-0 mb-1"
								style="color: #1D1B1B">
								<strong>{{ translate('cant_transfer_message') }}</strong>
							</p>
						</b-alert>
						<b-form
							v-if="canTransfer"
							@submit.prevent="transferTicketSubmit"
							@keydown="clearTransferError($event.target.name);"
							@change="clearTransferError($event.target.name);">
							<b-alert
								class="mt-2"
								show
								variant="warning">
								<p
									class="my-0 mb-1"
									style="color: #1D1B1B">
									<strong>{{ translate('important') }}</strong>
									<br>
									{{ translate('transfers_message') }}
								</p>
							</b-alert>

							<div
								id="userIdInput"
								class="form-group row"
								:errors="transferErrors['user_id']">
								<div class="col-12">
									<label
										class="label">
										{{ translate('transfer_to') }}
										<span class="text-danger">*</span>
									</label>
								</div>
								<div class="col-12">
									<search
										v-model="userData"
										:endpoint="endpoint"
										:init-value="userData"
										:name-value="'user_id'"
										:empty-result-message="translate('data_not_found')"
										:placeholder="'autocomplete_placeholder'"
										:default-params="{role:'distributor',status:'active,inactive'}"
										:custom-error="userIdErrors"
										q="label"
										return-key="id" />
								</div>
								<div class="col-12 mt-2">
									<div class="custom-control custom-checkbox">
										<input
											id="customCheck1"
											v-model="changeNames"
											type="checkbox"
											class="custom-control-input">
										<label
											class="custom-control-label"
											for="customCheck1">{{ translate('change_names') }}</label>
									</div>
								</div>
								<div
									v-if="!ticketInformation.is_parent"
									class="col-12 mt-3">
									<label for="attendant_type">{{ translate('attendee_type') }}</label>
									<select
										id="attendant_type"
										v-model="attendantType"
										:class="hasError('attendant_type') ? 'is-invalid' : ''"
										name="attendant_type"
										class="form-control">
										<option
											v-for="option in ticketInformation.attendees_options"
											:key="option"
											:value="option">
											{{ translate(option) }}
										</option>
									</select>
								</div>
							</div>
							<button
								:disabled="Object.keys(transferErrors).length > 0"
								class="btn btn-primary float-right">
								{{ translate('transfer') }}
							</button>
						</b-form>
					</div>
				</b-card-body>
			</b-card>
		</div>
	</div>
</template>
<script>

// import NumberInputSpinner from 'vue-number-input-spinner';
import { SEARCH_USERS } from '@/config/endpoint';
import InputText from '@/components/InputText';
import { EVENTS } from '@/settings/Events';
import {
	Profile, Events, EventTickets,
} from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'UserEventTicketForm',
	messages: [Profile, Events, EventTickets],
	components: {
		InputText,
		// NumberInputSpinner,
	},
	mixins: [WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		transferErrorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		ticketInformation: {
			type: Object,
			default() {
				return {};
			},
		},
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
		editsMessage: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			endpoint,
			userData: '',
			form: this.formImport,
			errors: this.errorsImport,
			transferErrors: this.transferErrorsImport,
			events: EVENTS,
			changeNames: true,
			attendantType: null,
		};
	},
	computed: {
		showForm() {
			return !this.loading && this.hasData;
		},
		hasData() {
			return Object.values(this.ticketInformation).length > 0;
		},
		userIdErrors() {
			if (typeof this.transferErrors.user_id !== 'undefined') {
				let userIdErrors = '';
				this.transferErrors.user_id.forEach((item) => { userIdErrors += `${item} `; });
				return userIdErrors;
			}
			return false;
		},
		canTransfer() {
			return this.ticketInformation.remaining_transfers > 0;
		},
		canEdit() {
			return this.ticketInformation.remaining_edits > 0;
		},
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
		transferErrorsImport(value) {
			this.transferErrors = value;
		},
		ticketInformation() {
			this.fetchFormData();
		},
	},
	methods: {
		ticketInfoSubmit() {
			this.$emit('requestData', this.form);
		},
		transferTicketSubmit() {
			const userId = this.userData;
			let payload = {};

			if (userId !== '') {
				payload = { user_id: userId, change_names: this.changeNames };
			}

			if (!this.ticketInformation.is_parent) {
				payload.attendant_type = this.attendantType;
			}
			this.$emit('transferTicketData', payload);
		},
		hasError(field) {
			if (typeof this.errors[field] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
		clearTransferError(field) {
			if (field) {
				delete this.transferErrors[field];
				this.transferErrors = { ...this.transferErrors };
			}
		},
		fetchFormData() {
			Object.keys(this.form).forEach((keyValue) => {
				this.form[keyValue] = this.ticketInformation[keyValue];
			});

			if (this.ticketInformation.event !== undefined) {
				this.form.event_code_name = this.ticketInformation.event.event_code_name;
			}

			this.attendantType = this.ticketInformation.attendant_type;
		},
	},
};
</script>
<style scoped>
.vnis {
	padding: 0 !important;
}
</style>
<style>
.custom-height {
	height: 36px !important;
}
</style>
